import React from "react";
import InformationCard from "./InformationCard";
import { faGamepad, faTrophy, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Offer</span>
        </h3>
        <p className="info-description">
          Play929 brings you an exciting world of coding challenges, fun games, and real money rewards. Test your coding skills, compete in challenges, and earn rewards while having fun. Our platform provides a seamless experience, quick payouts, and a community of like-minded gamers and coders.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Coding Challenges"
          description="Compete in exciting coding challenges designed to test your skills and knowledge. Solve problems, earn points, and rise up the leaderboard while enhancing your coding abilities."
          icon={faGamepad}
        />

        <InformationCard
          title="Tournaments & Competitions"
          description="Participate in thrilling tournaments and coding competitions for a chance to win real money. Join global events or local competitions and prove your coding mastery."
          icon={faTrophy}
        />

        <InformationCard
          title="Real Money Rewards"
          description="Win real money as you play! Play929 rewards your success in coding challenges and games with instant payouts, so you can enjoy your earnings with quick withdrawals."
          icon={faDollarSign}
        />
      </div>
    </div>
  );
}

export default Info;
