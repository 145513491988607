import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faUser } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "./p.png";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  const handleLoginBtnClick = async () => {
    if (!isButtonDisabled) {
      toast.info("Checking your connection, Please wait...", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
      });
  
      try {
        const response = await fetch("https://play929-e6eecaaffjgfbpec.southafricanorth-01.azurewebsites.net/api/Account/generate-token", {
          method: "POST",
          credentials: "include", 
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch token");
        }
  
        const { link } = await response.json();
  
        window.location.href = link;
      } catch (error) {
        toast.error("Failed to generate secure token. Please try again.");
        console.error(error);
      } finally {
        setIsButtonDisabled(false);
      }
    }
  };
  

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/" className="navbar-logo-container">
          <img src={logo} alt="Logo" className="navbar-logo" />
          Play929
        </Link>
      </h1>

  

      <div className="navbar-buttons">
        <button
          className="navbar-btn"
          type="button"
          disabled={isButtonDisabled}
          onClick={handleLoginBtnClick}
        >
          <FontAwesomeIcon icon={faUser} /> Log In
        </button>

        <a href="https://portal.play929.com/signup" className="navbar-create-btn">
  Create Account
</a>

      </div>

    </div>
  );
}

export default Navbar;
