import React from "react";
import CodingImage from "../Assets/atm.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/BookAppointment.css";

function BookAppointment() {
  

  const handleClick = () => {
    window.location.href = "https://portal.play929.com/signup";
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img src={CodingImage} alt="Play929 Challenge" className="ba-image1" />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Why Choose Play929</span>
        </h3>
        <p className="ba-description">
          Play929 is the ultimate destination to test your coding skills, play fun games, and win real money! Experience thrilling challenges, compete with others, and enjoy quick withdrawals. Join Play929 now and start earning rewards!
        </p>

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Exciting Coding Challenges
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Fun Games to Relax
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Real Money Rewards
        </p>
        <p className="ba-checks ba-check-last">
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#1E8FFD" }} /> Quick Withdrawals
        </p>

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faUserPlus} /> Create Account
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;
