import React from "react";
import { useLocation } from "react-router-dom";
import "../Styles/NotFound.css"; 

function NotFound() {
  const deployedURL = window.location.href;
  const location = useLocation();

  return (
    <div className="not-found-container">
      <h1 className="not-found-title">Oops! Page Not Found</h1>
      <p className="not-found-message">
        Sorry, the page you're looking for isn't available.
      </p>
      <p className="not-found-details">
        The requested URL <span className="highlight">{deployedURL}</span> was not found on this server.
      </p>
      <p className="not-found-details">
        Or, it could be that <span className="highlight">{location.pathname}</span> does not exist.
      </p>
      <p className="not-found-suggestion">Please check the URL or return to the homepage.</p>
      <a href="/" className="home-link">Go back to Home</a>
    </div>
  );
}

export default NotFound;
