import React from "react";
import SolutionStep from "./SolutionStep";
import logo from "../Assets/mark.svg";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={logo} alt="Play929 Logo" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Play929, where coding meets fun! Compete in exciting coding challenges, play fun games, and win real money with quick withdrawals. Join Play929 today and put your coding skills to the test!
        </p>

        <h4 className="about-text-title">How Play929 Works</h4>

        <SolutionStep
          title="Compete in Coding Challenges"
          description="Test your coding skills by solving fun and challenging problems. Rise through the ranks and prove you’re the best coder!"
        />

        <SolutionStep
          title="Play Fun Games"
          description="Enjoy a variety of engaging games that allow you to relax, compete, and have fun while earning rewards along the way."
        />

        <SolutionStep
          title="Win Real Money"
          description="Win real money with quick withdrawals. Compete and play to earn rewards that can be cashed out easily."
        />
      </div>
    </div>
  );
}

export default About;
