import React, { useEffect, useState  , useRef } from "react";
import GamingImage from "../Assets/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus , faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);
  const [statsVisible, setStatsVisible] = useState(false);
  const statsRef = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = () => {
    window.location.href = "https://portal.play929.com/signup";
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setStatsVisible(true);
        }
      },
      { threshold: 0.3 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">🎮 Play & Win Instantly</p>
          <h2 className="text-title">
            Play Coding Games & Win Real Money!
          </h2>
          <p className="text-description">
            Compete in exciting coding challenges, play fun games, and win real money with quick withdrawals. Join Play929 and put your coding skills to the test!
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faUserPlus } /> Get Your Welcome Bonus
          </button>
          <div className="text-stats" ref={statsRef}>
            <div className="text-stats-container">
              <p>
                <CountUp start={0} end={statsVisible ? 150000 : 0} duration={3} separator="," />
                +
              </p>
              <p>Players Worldwide</p>
            </div>

            <div className="text-stats-container">
              <p>
                <CountUp start={0} end={statsVisible ? 100 : 0} duration={3} />
                +
              </p>
              <p>Exciting Challenges</p>
            </div>

            <div className="text-stats-container">
              <p>Instant</p>
              <p>Withdrawals</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={GamingImage} alt="Gaming" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
